<template>
  <div>
      <div class="d-flex justify-content-between">
        <dx-util-number-box
          v-show="false"
          v-model="box.count" :disabled="true"
          type="default"
          label=" " :width="50"
          :min="1" class="mr-half"
        >
          <dx-util-validator>
            <dx-util-required-rule message="Count is required a field" />
          </dx-util-validator>
        </dx-util-number-box>
        <!-- <dx-util-text-box v-model="pack.description" type="default" label="Description" label-mode="floating" class="mr-1" /> -->
        <dx-util-number-box v-model="box.length" type="default"
          label="Length" :format="inchOrCm"
          class="mr-1" :min="1"
        >
          <dx-util-validator>
            <dx-util-range-rule :min="0.1" message="Length is required field" />
          </dx-util-validator>
        </dx-util-number-box>
        <dx-util-number-box v-model="box.width" type="default"
          label="Width" :format="inchOrCm"
          class="mr-1" :min="1"
        >
          <dx-util-validator>
            <dx-util-range-rule :min="0.1" message="Width is required field" />
          </dx-util-validator>
        </dx-util-number-box>
        <dx-util-number-box v-model="box.height" type="default"
          label="Height" :format="inchOrCm"
          class="mr-1" :min="1"
        >
          <dx-util-validator>
            <dx-util-range-rule :min="0.1" message="Height is required field" />
          </dx-util-validator>
        </dx-util-number-box>
        <dx-util-number-box v-model="box.weightLb" type="default"
          label="Weight" :format="lbOrKg"
          :min="0" class="mr-1"
        />
        <dx-util-number-box
          v-model="box.weightOz" type="default"
          label="Weight" :format="ozOrGr"
          class="mr-1" :min="0"
        />
        <dx-util-number-box
          v-if="currentOrder.fbmResourceType === 'AMAZON'"
          v-model="box.insuredValue" type="default"
          label="Insurance" format="$#0.00"
          :min="0"
          style="margin-right: 3px;" :step="0.1"
        />
        <div class="d-flex">
          <dx-util-button v-if="currentOrder.fbmResourceType === 'AMAZON'" icon="icon bi-coin" type="default" hint="Calculate the insurance value for  this box" class="mr-1 mt-half" @click="calculateInsuranceValue" />
          <dx-util-check-box v-if="currentOrder.fbmResourceType === 'AMAZON'" v-model="box.isHazmat" text="Hazmat" />
          <dx-util-number-box v-if="showInputField" label="Item Count" @focus-out="handleQuantityChange" />
          <dx-util-button v-else-if="false" text="Items" class="mt-half ml-half" hint="Select the items to put in this box" type="default" @click="openItemSelector" />
          <slot />
        </div>
      </div>
      <item-selector v-if="false" :component-id="itemSelectorCompId" :box="box.shipBox" :box-id="box.id" />
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import useFbmState from '../useFbmStates'

export default {
  components: {
    // 'item-selector': () => import('./ItemSelectorForBox.vue'),
  },
  props: {
    boxId: {
      type: String,
      default: '',
    },
  },
  setup() {
    const {
      currentOrder, getShippingLabels, orderItems, selectedOrderItems,
      boxes,
    } = useFbmState()

    return {
      currentOrder,
      getShippingLabels,
      orderItems,
      selectedOrderItems,
      boxes,
    }
  },
  data() {
    return {
      itemSelectorCompId: '',
      box: {
        id: '',
        count: 1,
        length: 0,
        width: 0,
        height: 0,
        weightLb: 0,
        weightOz: 0,
        description: '',
        insuredValue: 0,
        isHazmat: false,
        singleItemUpdateQty: 0,
        shipBox: [],
      },
    }
  },
  computed: {
    inchOrCm() {
      return this.currentOrder.shippingAddress.country.code2 === 'USA' || this.currentOrder.shippingAddress.country.code2 === 'US' ? '#0.00 in' : '#0.00 cm'
    },
    lbOrKg() {
      return this.currentOrder.shippingAddress.country.code2 === 'USA' || this.currentOrder.shippingAddress.country.code2 === 'US' ? '#0 lb' : '#0 kg'
    },
    ozOrGr() {
      return this.currentOrder.shippingAddress.country.code2 === 'USA' || this.currentOrder.shippingAddress.country.code2 === 'US' ? '#0 oz' : '#0 gr'
    },
    skuCount() {
      const orderSkuCounts = this.orderItems.reduce((acc, order) => {
        acc[order.orderSku] = (acc[order.orderSku] || 0) + 1
        return acc
      }, {})
      return Object.keys(orderSkuCounts).length
    },
    orderSkuCount() {
      return this.orderItems.length
    },
    itemCount() {
      return this.orderItems.reduce((acc, cur) => acc + cur.orderQuantity, 0)
    },
    boxCount() {
      return this.boxes.length
    },
    showInputField() {
      return this.orderSkuCount === 1 && this.itemCount > 1 && this.boxCount > 1
    },
  },
  mounted() {
    this.box = this.boxes.filter(el => el.id === this.boxId)[0]
  },
  methods: {
    openItemSelector() {
      this.itemSelectorCompId = uuidv4()
    },
    calculateInsuranceValue() {
      this.box.insuredValue = this.box.shipBox.reduce((totalValue, item) => {
        const itemTotalValue = item.price * item.orderQuantity
        return totalValue + itemTotalValue
      }, 0)
    },
    handleQuantityChange(e) {
      const qty = e.event.target.value
      const boxIndex = this.boxes.findIndex(el => el.id === this.boxId)
      if (boxIndex !== -1) {
        this.$set(this.boxes[boxIndex], 'singleItemUpdateQty', qty)
      }
    },

  },
}
</script>

<style lang="scss" scoped>

</style>
